import { graphql, Link } from "gatsby"
import React, { useEffect, useState } from "react"
import GlobalNavi from "../../components/GlobalNavi"
import { Box } from "@material-ui/core"
import Portrait from "../../components/Portrait"
import ChapterThumb from "../../components/ChapterThumb"
import css from "./index.module.scss"
import SEO from "../../components/seo"

export default function TalkIndex(props) {
  const [activeTabState, setActiveTab] = useState(0)
  const tab1Class = activeTabState === 0 ? "" : css.disabled
  const tab2Class = activeTabState === 1 ? "" : css.disabled
  const tab3Class = activeTabState === 2 ? "" : css.disabled
  const tabContent1Class = activeTabState === 0 ? css.active : css.disabled
  const tabContent2Class = activeTabState === 1 ? css.active : css.disabled
  const tabContent3Class = activeTabState === 2 ? css.active : css.disabled

  // console.log(props)

  const getImageFromQuery = slug => {
    for (const data of props.data.allFile.edges) {
      if (data.node.name === slug) {
        return data.node.childImageSharp
      }
    }

    return ""
  }

  const switchTab = index => {
    setActiveTab(index)
    sessionStorage.setItem("ACTIVE_TAB", `t` + index)
  }

  useEffect(() => {
    let savedTabIndex = sessionStorage.getItem("ACTIVE_TAB")
    if (savedTabIndex) {
      const tabIndex = Number(savedTabIndex.replace(`t`, ``))
      setActiveTab(tabIndex)
    }
    return () => {}
  }, [setActiveTab])

  return (
    <div>
      <SEO title={`おしゃべり対話鑑賞`} />
      <GlobalNavi />

      <div className="l-wrapper">
        <nav aria-label="パンくずリスト">
          <ul className={`breadcrumb`}>
            <li>
              <Link to={`/`}>Home</Link>
            </li>
            <li>
              <Link to={`/talk`}>おしゃべり対話鑑賞</Link>
            </li>
          </ul>
        </nav>
        <h2 className={`fontLvH2`}>おしゃべり対話鑑賞</h2>
        <Box className={`l-grid l-grid--xs`}>
          <div className={`l-grid__5 l-grid--xs__12`}>
            <p className={`fontLvBody`}>
              ブックで紹介した１５作品を、篠原ともえさんと西岡克浩さん（第1部）、中川美枝子さんとロバート
              キャンベルさん（第2部）、中川美枝子さんと華雪さん（第3部）が、対話をしながら鑑賞しています。
            </p>
          </div>
        </Box>

        <Box
          className={`l-grid l-grid--xs`}
          mt={{ xs: 2.5, sm: 5 }}
          mb={{ xs: 2.5, sm: 5 }}
        >
          <div
            className={`l-grid__10 l-grid--xs__12`}
            aria-label="タブ切り替えメニューです。次のボタンを選択するとタブが切り替わります。"
          >
            <ul className={css.tab}>
              <li>
                <button
                  className={tab1Class}
                  onClick={() => {
                    switchTab(0)
                  }}
                >
                  篠原ともえ
                  <br className={`pc-none`} />×<br className={`pc-none`} />
                  西岡克浩
                </button>
              </li>
              <li>
                <button
                  className={tab2Class + ` ` + css.small}
                  onClick={() => {
                    switchTab(1)
                  }}
                >
                  ロバート キャンベル
                  <br className={`pc-none`} />×<br className={`pc-none`} />
                  中川美枝子
                </button>
              </li>
              <li>
                <button
                  className={tab3Class}
                  onClick={() => {
                    switchTab(2)
                  }}
                >
                  中川美枝子
                  <br className={`pc-none`} />×<br className={`pc-none`} />
                  華雪
                </button>
              </li>
            </ul>

            <section
              className={css.tabContent + ` ` + tabContent1Class}
              id={`section-1`}
              aria-label="タブのコンテンツ１つ目"
            >
              <div className={css.tabContentInner}>
                <h2>
                  <div className={`fontLvBodyL`}>第 1 部</div>
                  <div className={css.tabContentTitle}>
                    篠原ともえさん × 西岡克浩さん <br />
                  </div>
                </h2>

                <p>
                  <small>
                    ＊2021年11月17日をもっておしゃべり対話鑑賞第1部の公開は終了しました
                  </small>
                </p>
                <div className={css.portraitBox}>
                  <Portrait
                    src="/images/navigators/thumb-tomoe-shinohara-white.png"
                    title={`篠原ともえ`}
                  />
                  <Portrait
                    src="/images/navigators/thumb-katuhiro-nishioka-white.png"
                    title={`西岡克浩`}
                  />
                </div>

                <div className={`l-wrapper__inner`}>
                  <div className={css.thumbList}>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 1`}
                        title={`イントロダクション`}
                        disabled={true}
                        childImageSharp={getImageFromQuery(
                          "shinohara-nishioka-1"
                        )}
                        href={`/talk/tomoe-shinohara-katuhiro-nishioka?time=1`}
                      />
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 2`}
                        title={`井口直人さんはコピー機で作品を制作`}
                        disabled={true}
                        childImageSharp={getImageFromQuery(
                          "shinohara-nishioka-2"
                        )}
                        href={`/talk/tomoe-shinohara-katuhiro-nishioka?time=163`}
                      >
                        作品: <Link to={"/works/naoto-iguchi"}>井口直人</Link>
                        <br />
                        応答する人:{" "}
                        <Link to={`/works/masayo-funakoshi`}>
                          船越雅代
                        </Link> /{" "}
                        <Link to={"/works/teppei-kaneuji"}>金氏徹平</Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 3`}
                        title={`井口さん作品に料理で応答　船越雅代さんの表現`}
                        disabled={true}
                        childImageSharp={getImageFromQuery(
                          "shinohara-nishioka-3"
                        )}
                        href={`/talk/tomoe-shinohara-katuhiro-nishioka?time=494`}
                      >
                        作品: <Link to={"/works/naoto-iguchi"}>井口直人</Link>
                        <br />
                        応答する人:{" "}
                        <Link to={`/works/masayo-funakoshi`}>
                          船越雅代
                        </Link> /{" "}
                        <Link to={"/works/teppei-kaneuji"}>金氏徹平</Link>
                      </ChapterThumb>
                    </div>

                    <div>
                      <ChapterThumb
                        capter={`Chapter 4`}
                        title={`金氏徹平《白地図》シリーズと井口さん作品の共鳴`}
                        disabled={true}
                        childImageSharp={getImageFromQuery(
                          "shinohara-nishioka-4"
                        )}
                        href={`/talk/tomoe-shinohara-katuhiro-nishioka?time=666`}
                      >
                        作品: <Link to={"/works/naoto-iguchi"}>井口直人</Link>
                        <br />
                        応答する人:{" "}
                        <Link to={`/works/masayo-funakoshi`}>
                          船越雅代
                        </Link> /{" "}
                        <Link to={"/works/teppei-kaneuji"}>金氏徹平</Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 5`}
                        title={`リ・ビンユアンのパフォーマンス映像`}
                        disabled={true}
                        childImageSharp={getImageFromQuery(
                          "shinohara-nishioka-5"
                        )}
                        href={`/talk/tomoe-shinohara-katuhiro-nishioka?time=845`}
                      >
                        作品:{" "}
                        <Link to={"/works/binyuan-li_drawingboard"}>
                          リ・ビンユアン《画板100×40》
                        </Link>
                        <br />
                        応答する人: <Link to={"/works/kasetsu"}>
                          華雪
                        </Link> /{" "}
                        <Link to={"/works/isokatsu_satoshi-iitsuka"}>
                          磯子区障害者地域活動ホーム＋飯塚聡
                        </Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 5-2`}
                        title={`リ・ビンユアンのパフォーマンス映像`}
                        disabled={true}
                        childImageSharp={getImageFromQuery(
                          "shinohara-nishioka-5-2"
                        )}
                        href={`/talk/tomoe-shinohara-katuhiro-nishioka?time=985`}
                      >
                        作品:{" "}
                        <Link
                          to={"/works/binyuan-li_until-the-bridge-collapses"}
                        >
                          リ・ビンユアン《橋が壊れるまで》
                        </Link>
                        <br />
                        応答する人: <Link to={"/works/kasetsu"}>
                          華雪
                        </Link> /{" "}
                        <Link to={"/works/isokatsu_satoshi-iitsuka"}>
                          磯子区障害者地域活動ホーム＋飯塚聡
                        </Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 6`}
                        title={`書による応答　華雪さんの「一」`}
                        disabled={true}
                        childImageSharp={getImageFromQuery(
                          "shinohara-nishioka-6"
                        )}
                        href={`/talk/tomoe-shinohara-katuhiro-nishioka?time=1177`}
                      >
                        作品: リ・ビンユアン
                        <br />
                        応答する人: <Link to={"/works/kasetsu"}>
                          華雪
                        </Link> /{" "}
                        <Link to={"/works/isokatsu_satoshi-iitsuka"}>
                          磯子区障害者地域活動ホーム＋飯塚聡
                        </Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 7`}
                        title={`音も身振りもとまどいも　みんな違う表現`}
                        disabled={true}
                        childImageSharp={getImageFromQuery(
                          "shinohara-nishioka-7"
                        )}
                        href={`/talk/tomoe-shinohara-katuhiro-nishioka?time=1342`}
                      >
                        作品: リ・ビンユアン
                        <br />
                        応答する人: <Link to={"/works/kasetsu"}>
                          華雪
                        </Link> /{" "}
                        <Link to={"/works/isokatsu_satoshi-iitsuka"}>
                          磯子区障害者地域活動ホーム＋飯塚聡
                        </Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 8`}
                        disabled={true}
                        title={`刻々と移り変わる光景を描く　川戸由紀さんの作品`}
                        childImageSharp={getImageFromQuery(
                          "shinohara-nishioka-8"
                        )}
                        href={`/talk/tomoe-shinohara-katuhiro-nishioka?time=1615`}
                      >
                        作品: <Link to={"/works/yuki-kawado"}>川戸由紀</Link>
                        <br />
                        応答する人:{" "}
                        <Link to={"/works/yuichi-yokoyama"}>横山裕一</Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 9`}
                        title={`花火を楽しむふたり　横山裕一さんの応答`}
                        disabled={true}
                        childImageSharp={getImageFromQuery(
                          "shinohara-nishioka-9"
                        )}
                        href={`/talk/tomoe-shinohara-katuhiro-nishioka?time=1800`}
                      >
                        作品: <Link to={"/works/yuki-kawado"}>川戸由紀</Link>
                        <br />
                        応答する人:{" "}
                        <Link to={"/works/yuichi-yokoyama"}>横山裕一</Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 10`}
                        title={`対話鑑賞　どうでしたか？`}
                        disabled={true}
                        childImageSharp={getImageFromQuery(
                          "shinohara-nishioka-10"
                        )}
                        href={`/talk/tomoe-shinohara-katuhiro-nishioka?time=2235`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className={css.tabContent + ` ` + tabContent2Class}
              id={`section-2`}
              aria-label="タブのコンテンツ２つ目"
            >
              <div className={css.tabContentInner}>
                <h2>
                  <div className={`fontLvBodyL`}>第 2 部</div>
                  <div className={css.tabContentTitle}>
                    ロバート キャンベルさん × 中川美枝子さん
                  </div>
                </h2>

                <div className={css.portraitBox}>
                  <Portrait
                    src="/images/navigators/thumb-robert-campbell-white.png"
                    title={`ロバート キャンベル`}
                  />
                  <Portrait
                    src="/images/navigators/thumb-mieko-nakagawa-white.png"
                    title={`中川美枝子`}
                  />
                </div>
                <div className={`l-wrapper__inner`}>
                  <div className={css.thumbList}>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 1`}
                        title={`イントロダクション: はじめまして`}
                        childImageSharp={getImageFromQuery(
                          "campbell-nakagawa-1"
                        )}
                        href={`/talk/robert-campbell-mieko-nakagawa?time=1`}
                      />
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 2`}
                        title={`夢を語る、聴くこと　山本高之さん作品をめぐって`}
                        childImageSharp={getImageFromQuery(
                          "campbell-nakagawa-2"
                        )}
                        href={`/talk/robert-campbell-mieko-nakagawa?time=584`}
                      >
                        作品:{" "}
                        <Link to={"/works/takayuki-yamamoto"}>山本高之</Link>
                        <br />
                        応答する人:{" "}
                        <Link to={`/works/mieko-nakagawa`}>
                          中川美枝子
                        </Link> /{" "}
                        <Link to={"/works/mieko-matsumoto"}>松本美枝子</Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 3`}
                        title={`相手がいることへの葛藤　中川さんの応答`}
                        childImageSharp={getImageFromQuery(
                          "campbell-nakagawa-3"
                        )}
                        href={`/talk/robert-campbell-mieko-nakagawa?time=1546`}
                      >
                        作品:{" "}
                        <Link to={"/works/takayuki-yamamoto"}>山本高之</Link>
                        <br />
                        応答する人:{" "}
                        <Link to={`/works/mieko-nakagawa`}>
                          中川美枝子
                        </Link> /{" "}
                        <Link to={"/works/mieko-matsumoto"}>松本美枝子</Link>
                      </ChapterThumb>
                    </div>

                    <div>
                      <ChapterThumb
                        capter={`Chapter 4`}
                        title={`周囲の人の心の風景　松本さんの応答`}
                        childImageSharp={getImageFromQuery(
                          "campbell-nakagawa-4"
                        )}
                        href={`/talk/robert-campbell-mieko-nakagawa?time=2092`}
                      >
                        作品:{" "}
                        <Link to={"/works/takayuki-yamamoto"}>山本高之</Link>
                        <br />
                        応答する人:{" "}
                        <Link to={`/works/mieko-nakagawa`}>
                          中川美枝子
                        </Link> /{" "}
                        <Link to={"/works/mieko-matsumoto"}>松本美枝子</Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 5`}
                        title={`対話鑑賞　どうでしたか？`}
                        childImageSharp={getImageFromQuery(
                          "campbell-nakagawa-5"
                        )}
                        href={`/talk/robert-campbell-mieko-nakagawa?time=2792`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className={css.tabContent + ` ` + tabContent3Class}
              id={`section-3`}
              aria-label="タブのコンテンツ３つ目"
            >
              <div className={css.tabContentInner}>
                <h2>
                  <div className={`fontLvBodyL`}>第 3 部</div>
                  <div className={css.tabContentTitle}>
                    中川美枝子さん × 華雪さん
                  </div>
                </h2>

                <div className={css.portraitBox}>
                  <Portrait
                    src="/images/navigators/thumb-mieko-nakagawa-white.png"
                    title={`中川美枝子`}
                  />
                  <Portrait
                    src="/images/navigators/thumb-kasetsu-white.png"
                    title={`華雪`}
                  />
                </div>

                <div className={`l-wrapper__inner`}>
                  <div className={css.thumbList}>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 1`}
                        title={`イントロダクション: はじめまして`}
                        childImageSharp={getImageFromQuery(
                          "nakagawa-kasetsu-1"
                        )}
                        href={`/talk/mieko-nakagawa-kasetsu?time=1`}
                      />
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 2`}
                        title={`切れ目なく続く想いの形　鎌江一美さんの作品`}
                        childImageSharp={getImageFromQuery(
                          "nakagawa-kasetsu-2"
                        )}
                        href={`/talk/mieko-nakagawa-kasetsu?time=555`}
                      >
                        作品: <Link to={"/works/kazumi-kamae"}>鎌江一美</Link>
                        <br />
                        応答する人:{" "}
                        <Link to={`/works/mari-kashiwagi`}>柏木麻里</Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 3`}
                        title={`種と細胞　柏木麻里さんの応答`}
                        childImageSharp={getImageFromQuery(
                          "nakagawa-kasetsu-3"
                        )}
                        href={`/talk/mieko-nakagawa-kasetsu?time=1353`}
                      >
                        作品: <Link to={"/works/kazumi-kamae"}>鎌江一美</Link>
                        <br />
                        応答する人:{" "}
                        <Link to={`/works/mari-kashiwagi`}>柏木麻里</Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 4`}
                        title={`触って確かめる思い出　杉浦篤さんの作品`}
                        childImageSharp={getImageFromQuery(
                          "nakagawa-kasetsu-4"
                        )}
                        href={`/talk/mieko-nakagawa-kasetsu?time=2046`}
                      >
                        作品: <Link to={"/works/atsushi-sugiura"}>杉浦篤</Link>
                        <br />
                        応答する人: <Link to={`/works/dj-sniff`}>dj sniff</Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 5`}
                        title={`傷つけるというタブー　dj sniffさんの応答`}
                        childImageSharp={getImageFromQuery(
                          "nakagawa-kasetsu-5"
                        )}
                        href={`/talk/mieko-nakagawa-kasetsu?time=2963`}
                      >
                        作品: <Link to={"/works/atsushi-sugiura"}>杉浦篤</Link>
                        <br />
                        応答する人: <Link to={`/works/dj-sniff`}>dj sniff</Link>
                      </ChapterThumb>
                    </div>
                    <div>
                      <ChapterThumb
                        capter={`Chapter 6`}
                        title={`対話鑑賞　どうでしたか？`}
                        childImageSharp={getImageFromQuery(
                          "nakagawa-kasetsu-6"
                        )}
                        href={`/talk/mieko-nakagawa-kasetsu?time=3993`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Box>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/talk/.+.jpg/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 420) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
